import { DoctorUserUpdate, UserInfo, UserProfile } from "../models/entity/user";
import { saasHttpInstance } from "../utils/http";

export class UserServiceClient {
  async getUserProfile(): Promise<UserProfile> {
    return saasHttpInstance.get("/system/weChat/RefreshToken");
  }

  // async getUserInfo(source: boolean): Promise<UserInfo> {
  //   return saasHttpInstance.get("/system/WxUser/getUserInf", {
  //     params: { source },
  //   });
  // }

  async getUserInfo(params: { idcard: string }): Promise<UserInfo> {
    return saasHttpInstance.post("/mini/project/old/queryEmpiid", params);
  }

  async switchHospital(hospitalId: string) {
    return saasHttpInstance.get(`/system/WxUser/switchHospital/${hospitalId}`);
  }

  async updateDoctorInfo(payload: Partial<DoctorUserUpdate>) {
    return saasHttpInstance.post("/system/WxUser/updateRoleInf", payload);
  }

  async getPublicKey(token: string) {
    return saasHttpInstance.post(
      "/system/weChat/getPublicKey",
      {},
      {
        headers: { Authorization: token },
      }
    );
  }

  async initSrp(data: any) {
    return saasHttpInstance.get("/visit-api/init/srp");
  }

  async login() {
    return saasHttpInstance.post("/login");
  }
}
