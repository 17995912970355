/** @jsxImportSource @emotion/react */
// import { css } from "@emotion/react";
import { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useAppUserIdentityType } from "../../../hooks";
import useDoctorStore from "../../../store/doctor";
import usePatientStore from "../../../store/patient";
import DropdownItemNum from "./DropdownItemNum";
import DropdownItemProgress from "./DropdownItemProgress";
import DropdownSelect from "../../DropdownSelect";
import DropdownTitle from "../../DropdownSelect/DropdownTitle";
import { GetProjectListPromiseType } from "../../../models/entity/followUpProject";
import { useSearchParams } from "react-router-dom";
// import { SAAS_TOKEN_KEY } from "../../../store/core/user";

export interface ProjectSelectType {
  type: "OneNoColor" | "OneBandColor";
  keyId: string;
  className?: string;
  selectedProjectId?: string;
}

export default observer(function ProjectSelect(props: ProjectSelectType) {
  const { type, keyId, className, selectedProjectId } = props;
  const appUserIdentityType = useAppUserIdentityType();
  const doctorStore = useDoctorStore();
  const patientStore = usePatientStore();
  const [search] = useSearchParams();

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { userLogicStore, followUpStore } = useMemo(
    () => (isDoctor ? doctorStore : patientStore),
    [doctorStore, isDoctor, patientStore]
  );

  const { count, projectList, selectedProject, setSelectedProject } =
    followUpStore;

  useEffect(() => {
    if (projectList.length) {
      // !followUpStore.selectedProject?.id && setSelectedProject(projectList[0]);

      if (selectedProjectId) {
        setSelectedProject(
          projectList?.find((v) => v.projectId === selectedProjectId) ||
            projectList?.[0]
        );
      } else {
        setSelectedProject(projectList?.[0] || {});
      }
    }
  }, [selectedProjectId, projectList, setSelectedProject]);

  useEffect(() => {
    // const saasToken = localStorage.getItem(SAAS_TOKEN_KEY);
    // if (userLogicStore.saasToken) {

    // const idcard = localStorage.getItem("idcard");
    const empiOrUserid = search.get("empiOrUserid") ?? "";
    type === "OneBandColor"
      ? followUpStore.visitHome({
          empiOrUserid,
          type: 1,
          // projectId: "-1",
        } as any)
      : followUpStore.getProjectList({
          empiOrUserid,
          type: 1,
        } as any);
    // }
  }, [isDoctor, followUpStore, type, userLogicStore.saasToken, search]);

  return (
    <>
      {projectList.length > 0 ? (
        <DropdownSelect<GetProjectListPromiseType>
          selectValue={selectedProject}
          keyId={keyId}
          labelStr={"projectName"}
          // valueStr={"id"}
          valueStr={"projectId"}
          list={projectList}
          type={type}
          arrowFill={false}
          className={className}
          titleRender={
            <>
              <DropdownTitle
                type="title-bold"
                changeColor={false}
                title={selectedProject?.projectName}
              />
              {type === "OneNoColor" ? (
                <DropdownItemNum
                  key={selectedProject.projectId}
                  count={count}
                  data={selectedProject}
                />
              ) : (
                <DropdownItemProgress
                  key={selectedProject.projectId}
                  data={selectedProject}
                />
              )}
            </>
          }
          checkedItem={(item: GetProjectListPromiseType) => {
            setSelectedProject(item);
          }}
        >
          {(val: GetProjectListPromiseType) => (
            <div className="flex flex-col">
              <DropdownTitle
                type="title-bold"
                changeColor={false}
                title={val.projectName + "123"}
              />
              {type === "OneNoColor" ? (
                <DropdownItemNum count={count} data={val} />
              ) : (
                <DropdownItemProgress data={val} />
              )}
            </div>
          )}
        </DropdownSelect>
      ) : null}
    </>
  );
});
