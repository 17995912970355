/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { useTheme } from "../../theme";
import { Ellipsis, Toast } from "antd-mobile";
// import moment from "moment";
import { Typography, ArrowIcon } from "../../components";
import formIcon from "../../assets/icons/formIcon.svg";
import teachIcon from "../../assets/icons/teachIcon.svg";
import patientEdit from "../../assets/icons/patientEdit.svg";
import { FormListType } from "../../models/entity/followUpProject";
import { FormOriginType } from "../FormEditor/FormGatherWrapper";
import { serviceClient } from "../../service";
import { useSearchParams } from "react-router-dom";
import { uid } from "uid";

interface FormEducateListType {
  type: FormOriginType;
  list?: FormListType[];
  status?: number;
  time?: string[];
  time2?: string[];
  nodeName?: string;
  patientId?: string; // 患者id
  projectId?: string; // 项目id
}

export default observer(function FormEducateList(props: FormEducateListType) {
  //time2
  const { type, list, status } = props;
  const theme = useTheme();
  const [search] = useSearchParams();

  const iType = useMemo(() => {
    // switch (type) {
    //   case "FollowUpProject":
    //     return 1;
    //   case "HealthManageProject":
    //     return 2;
    //   default:
    //     return 0;
    // }

    return type === "FollowUpProject" || type === "HealthManageProject";
  }, [type]);

  const isHealthManage = useMemo(() => {
    return (
      type === "HealthManageProject" ||
      type === "HealthManagePatient" ||
      type === "HealthManage"
    );
  }, [type]);

  const isFollowUp = useMemo(() => {
    return !iType && !isHealthManage;
  }, [iType, isHealthManage]);

  // 患者随访表单填写状态
  const patientForm = useMemo(() => {
    return isFollowUp && (status === 1 || status === 3);
  }, [isFollowUp, status]);

  const statusText = useCallback((formWrite: number) => {
    // const save = formWrite === 0 && formContent && formContent !== ""; // 暂存
    // const submit = formWrite === 1; // 提交
    // const noWrite = formWrite === 0 && (!formContent || formContent === ""); // 未填
    const noWrite = formWrite === 0; // 未填写
    const save = formWrite === 2; // 暂存
    const submit = formWrite === 3; // 提交
    // let text = "";
    const _text = save ? "已保存" : submit ? "已提交" : noWrite ? "去填写" : "";
    return _text;
    // if (isHealthManage) {
    //   text = submit ? "已提交" : noWrite ? "去填写" : "";
    // } else {
    //   switch (status) {
    //     case 0:
    //       text = "未开始";
    //       break;
    //     case 1:
    //       text = _text;
    //       break;
    //     case 2:
    //       text = "已提交";
    //       break;
    //     case 3:
    //       text = _text;
    //       break;
    //     case 4:
    //       text = "已逾期";
    //       break;
    //     default:
    //       break;
    //   }
    // }
    // return text;
  }, []);

  const handleClick = useCallback(
    async (item: FormListType) => {
      const { formId, ipatientrelid, nodeId } = item as any;
      const winFillFormUrl = (window as any)._settings.fillFormUrl;
      let ipiid = item.ipiid;
      if (!ipiid) {
        const param: any = {};
        param.empiid = search.get("empiOrUserid") ?? "";
        param.pageid = formId;
        param.visitid = nodeId;
        param.piid = uid();
        param.patientrelid = ipatientrelid;
        ipiid = await serviceClient.followUpProjectAll.generateIpid(param);
      }

      if (ipiid) {
        const fillFormUrl = `${winFillFormUrl}/Display/Scan?id=${formId}&piid=${ipiid}&tkn=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxMzgzN2ExYS04NDc4LTQwN2UtYTdlYS02Yjg5MjMxY2RhNWMiLCJpYXQiOjE3MTcxMjA5NTQsImlzcyI6InNlcnZlciIsImF1ZCI6IndlYiIsInVuaXF1ZV9uYW1lIjoi57O757uf566h55CG5ZGYIiwidXNlcmlkIjoic3lzIiwiaXNkb2N0b3IiOiJUcnVlIiwibmJmIjoxNzE3MTIwOTU0LCJleHAiOjIwMzI2NTM3NTR9.uXC7eDkYT5UyNYuAoZPkytYLT-EE0ScTZsZWs1MY_eg`;
        window.location.href = fillFormUrl;
      } else {
        Toast.show("ipiid不能为空");
      }
    },
    [search]
  );

  return (
    <>
      {list?.map((item: FormListType, index: number) => {
        return (
          <div
            key={index}
            className="flex justify-between items-center py-4 pl-2 pr-4"
            css={css`
              border-bottom: 1px solid ${theme.borderColor};
            `}
            onClick={() => handleClick(item)}
          >
            <div className="flex items-center">
              <img
                className="w-6 h-6"
                src={item.formType === 1 ? teachIcon : formIcon}
                alt=""
              />
              <div className="flex items-center ml-2">
                {(patientForm || isHealthManage) &&
                  item.formWrite === 0 &&
                  item.formContent === "" && (
                    <img className="w-6 h-6 mr-1" src={patientEdit} alt="" />
                  )}
                <Typography
                  type="title"
                  css={css`
                    font-weight: 400;
                  `}
                >
                  <Ellipsis
                    direction="end"
                    rows={1}
                    content={item.formName || ""}
                  />
                </Typography>
              </div>
            </div>
            <div>
              {/* 这一块 UI 上显示状态的包括：患者端-随访-健康管理、医生端-患者列表-患者详情-健康管理 */}
              {/* 未开始的状态应该就是未开始，表单不能填写；进行中就会有未填写、已保存、已提交、已完成的是应该都是已经提交的，逾期 */}
              {item.formType !== 1 && (
                <Typography type="secondary" className="mr-1">
                  {iType
                    ? item?.gatherList?.length || 0
                    : statusText(item?.formWrite as number)}
                </Typography>
              )}
              <ArrowIcon
                direction="right"
                css={css`
                  font-size: 14px;
                `}
              />
            </div>
          </div>
        );
      })}
    </>
  );
});
