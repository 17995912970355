import { useMemo } from "react";
import AppPatient from "./AppPatient";

import { AppUserIdentityTypeContext } from "./hooks";
import "./styles/common.css";
import { getAppUserIdentityType } from "./utils";

export default function App() {
  const appUserIdentityType = useMemo(() => {
    return getAppUserIdentityType();
  }, []);
  
  return (
    <AppUserIdentityTypeContext.Provider value={appUserIdentityType as any}>
      <AppPatient />
    </AppUserIdentityTypeContext.Provider>
  );
}
