import { AxiosRequestConfig } from "axios";
import {
  Patient,
  GetPatientListParamsType,
  GetNodeListPromiseType,
  PatientFocusParamsType,
  SerachPatientsType,
  FileListParamsType,
  projectPatient,
} from "../models/entity/patient";
import { hospitalHttpInstance, saasHttpInstance } from "../utils/http";

export class PatientServiceClient {
  async getList(params: GetPatientListParamsType): Promise<projectPatient> {
    return hospitalHttpInstance.post("/mini/project/patient", params, {
      customConfig: { isShowLoading: false },
    } as AxiosRequestConfig<any>);
  }

  async getNodeList(id: string): Promise<GetNodeListPromiseType[]> {
    return hospitalHttpInstance.get(`/mini/project/nodeData/${id}`);
  }

  // type = 1 是患者 type = 2 是医生
  async patientInfo(params: {
    projectId: string;
    empiOrUserid: string;
  }): Promise<Patient> {
    // return hospitalHttpInstance.get(`/mini/project/patientInfo/${params.id}`);
    return hospitalHttpInstance.get("/mini/project/old/patientInfo", {
      params,
    });
  }

  async patientFocus(params: PatientFocusParamsType) {
    return hospitalHttpInstance.post("/mini/project/patientFocus", params);
  }

  async serachPatients(params: string[]): Promise<SerachPatientsType[]> {
    return saasHttpInstance.post("/system/WxUser/serachPatients", params);
  }

  async patientFileList(params: FileListParamsType): Promise<any> {
    return hospitalHttpInstance.get("/mini/project/patientFileList", {
      params,
    });
  }

  async delFile(params: string[]) {
    return hospitalHttpInstance.post("/mini/project/delFile", params);
  }

  async renameFile(params: { id: string; name: string }) {
    return hospitalHttpInstance.post("/mini/project/rename", params);
  }

  async fileUpload(formData: FormData): Promise<any> {
    return hospitalHttpInstance({
      method: "post",
      url: "/system/patient/uploadPatientFile",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      // onUploadProgress: onUploadProgress || "", //  监听文件上传进度，实时更新变量uploadPercent进行显示
      transformRequest: [
        function () {
          return formData;
        },
      ],
    });
  }
}
