import { makeAutoObservable } from "mobx";
import { serviceClient } from "../../service";
import {
  FollowupPlanInfoType,
  FormListType,
  GetProjectListParamsType,
  GetProjectListPromiseType,
  HealthInfoType,
  SerachPatientCountPromiseType,
  SerachPatientCountType,
} from "../../models/entity/followUpProject";
import { Patient } from "../../models/entity/patient";
import { HomeParamsType } from "../../models/entity/home";

class FollowUpStore {
  projectList = [] as GetProjectListPromiseType[];
  selectedProject = {} as GetProjectListPromiseType;
  count = [] as SerachPatientCountPromiseType[];
  patientCountParams = [] as SerachPatientCountType[];
  info = {} as Patient; // 患者详情
  healthInfo = {} as HealthInfoType; // 健康管理
  patientHealthInfo = {} as HealthInfoType; // 患者端健康管理
  formInfo = {} as FollowupPlanInfoType; // 获取表单填写详情
  activeKey = "1" as string;
  // selectNodeItem = null as null | FollowupPlanInfoType;
  selectNodeItem = {
    status: -1,
    statusName: "全部",
  } as null | FollowupPlanInfoType;

  nodeName = "" as string; // 获取当前的节点名称
  nodeTime = [] as string[]; // 当前的节点时间
  gatherList = [] as FormListType[]; // 当前表单中的gatherList

  constructor() {
    makeAutoObservable(this);
  }

  setSelectNodeItem = (val: FollowupPlanInfoType | null) => {
    this.selectNodeItem = val;
  };

  setActiveKey = (val: string) => {
    this.activeKey = val;
  };

  setNodeName = (val: string) => {
    this.nodeName = val;
  };

  setNodeTime = (val: string[]) => {
    this.nodeTime = val;
  };

  setGatherList = (val: FormListType[]) => {
    this.gatherList = val;
  };

  setSelectedProject = (val: GetProjectListPromiseType) => {
    this.selectedProject = val;
  };

  getProjectList = async (params: GetProjectListParamsType) => {
    this.projectList = await serviceClient.followUpProject.getProjectList(
      params
    );
    this.patientCountParams = this.projectList.map(
      (item: GetProjectListPromiseType) => {
        return {
          empis: item.empIds,
          projectId: item.id,
        };
      }
    );
    if (window._settings.isWXLogin) {
      this.patientCountParams.length > 0 &&
        this.serachPatientCount(this.patientCountParams);
    }

    if (!this.selectedProject?.id) {
      this.selectedProject = this.projectList[0];
    }
  };

  serachPatientCount = async (params: SerachPatientCountType[]) => {
    this.count = await serviceClient.followUpProject.serachPatientCount(params);
  };

  visitHome = async (params: HomeParamsType) => {
    const res = await serviceClient.followUpProject.visitHome(params);
    this.projectList = res.map((item) => {
      return {
        ...item,
        empIds: [],
      };
    }) as any[];
    // if (!this.selectedProject?.id) {
    //   this.selectedProject = this.projectList[0];
    // }
  };

  patientInfo = async (projectId: string, empiOrUserid: string) => {
    this.info = await serviceClient.patient.patientInfo({
      projectId,
      empiOrUserid,
    });
  };

  healthManagement = async (id: string) => {
    this.healthInfo = await serviceClient.followUpProject.healthManagement(id);
  };

  patientManagement = async (id: string) => {
    this.patientHealthInfo =
      await serviceClient.followUpProject.patientManagement(id);
  };

  writeForm = async (params: {
    formId: string;
    nodeId: string;
    patientId: string;
    projectId: string;
    type: number;
  }) => {
    this.formInfo = await serviceClient.followUpProject.writeForm(params);
    return this.formInfo;
  };

  talk = async (params: {
    empiOrUserid: string;
    projectId: string;
    patientIds: string[];
  }) => {
    return await serviceClient.followUpProject.talk(params);
  };
}

export default FollowUpStore;
