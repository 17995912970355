/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useTheme } from "../../../theme";
import { Badge } from "antd-mobile";
import Collapse from "./CollapseMain";
import { FormOriginType } from "../../FormEditor/FormGatherWrapper";
import SelectNodePopup from "./SelectNodePopup";
import { Typography, ArrowIcon } from "../../../components";
import {
  FollowupPlanInfoType,
  statusList,
} from "../../../models/entity/followUpProject";
import { useAppUserIdentityType } from "../../../hooks";
import useDoctorStore from "../../../store/doctor";
import usePatientStore from "../../../store/patient";

interface FollowupPlanProps {
  type: FormOriginType;
  nodes?: FollowupPlanInfoType[];
  patientId?: string; // 患者id
  projectId?: string; // 项目id
}

export default observer(function FollowupPlan(props: FollowupPlanProps) {
  const { type = "FollowUp", nodes, patientId, projectId } = props;
  const theme = useTheme();
  const doctorStore = useDoctorStore();
  const patientStore = usePatientStore();
  const appUserIdentityType = useAppUserIdentityType();

  const [nodeVisible, setNodeVisible] = useState<boolean>(false);
  // const [selectItem, setSelectItem] = useState({} as FollowupPlanInfoType);

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { followUpStore } = useMemo(
    () => (isDoctor ? doctorStore : patientStore),
    [doctorStore, isDoctor, patientStore]
  );

  const selectItem = useMemo(
    () => followUpStore.selectNodeItem,
    [followUpStore.selectNodeItem]
  );

  const iType = useMemo(() => {
    return type === "FollowUpProject" || type === "HealthManageProject";
  }, [type]);

  const isBeing = useMemo(() => {
    return nodes?.filter((k) => k.status === 1);
  }, [nodes]);

  const noStart = useMemo(() => {
    return nodes?.filter((k) => k.status === 0);
  }, [nodes]);

  // 3和4都是逾期，他们的区别是 ，3是逾期后下面的表单仍可填写，4的话就是不能填的
  const collapseList = useMemo(() => {
    return nodes?.filter((item) => {
      if (selectItem?.status === 3 || selectItem?.status === 4) {
        return [3, 4].includes(item.status);
      } else if (selectItem?.status === -1) {
        return item.status !== -1;
      } else if (iType) {
        return item.status;
      } else {
        return item.status === selectItem?.status;
      }
    });
  }, [iType, nodes, selectItem?.status]);

  const handleStatusList = useMemo(() => {
    return statusList.filter((item) => Number(item.value) < 3);
  }, []);

  useEffect(() => {
    //有进行中的节点就默认选择进行中，没有进行中的节点就默认展示未开始的节点，否则就展示全部节点
    if (!iType && selectItem === null && nodes?.length) {
      const defaultValue = isBeing?.length
        ? isBeing[0]
        : noStart?.length
        ? noStart[0]
        : nodes[0];
      followUpStore.setSelectNodeItem(defaultValue);
    }

    if (iType) {
      followUpStore.setSelectNodeItem(null);
    }
  }, [followUpStore, iType, isBeing, noStart, nodes, selectItem]);

  // 选择节点筛选
  const selectNode = useCallback(() => {
    setNodeVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setNodeVisible(false);
  }, []);

  const handleSubmit = useCallback(
    (val: FollowupPlanInfoType) => {
      followUpStore.setSelectNodeItem(val);
      handleCancel();
    },
    [followUpStore, handleCancel]
  );

  return (
    <div
      className="h-full p-4"
      css={css`
        background: ${iType ? `${theme.whiteColor}` : `${theme.borderColor}`};
      `}
    >
      {!iType && nodes?.length && (
        <div className="flex justify-between items-center mb-4">
          {/* 当前没有正在进行中节点时，默认展示未开始节点 */}
          <div className="inline-block" onClick={selectNode}>
            <ArrowIcon direction={"down"}>
              <Typography>{`${selectItem?.statusName}节点 ${
                collapseList?.length || 0
              }`}</Typography>
            </ArrowIcon>
          </div>
          {/* 只有选择全部节点时才展示 */}
          {selectItem?.status === -1 && (
            <div className="flex items-center">
              {handleStatusList.map((item) => {
                return (
                  <div key={item.value}>
                    <Badge
                      color={`${theme[item.colorType]}`}
                      content={Badge.dot}
                      css={css`
                        min-width: 8px;
                        width: 8px;
                        height: 8px;
                        border-radius: 4px;
                      `}
                    />
                    <Typography type="mini" className="pl-1 pr-2">
                      {item.label}
                    </Typography>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}

      <Collapse
        type={type}
        // defaultActiveKey={["0"]}
        list={collapseList}
        patientId={patientId}
        projectId={projectId}
      />

      {/* 弹窗 */}
      {nodeVisible && (
        <SelectNodePopup
          visible={nodeVisible}
          list={nodes}
          selectValue={selectItem}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
});
