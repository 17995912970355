import Home from "../../pagesPatient/Home";
import MedicalRecord from "../../coreUIComponents/HospitalData/MedicalRecord";
import ReportList from "../../coreUIComponents/HospitalData/ReportList";
import ReportDetail from "../../coreUIComponents/HospitalData/ReportDetail";
import FollowUp from "../../pagesPatient/FollowUp";
import PreachAndTeach from "../../coreUIComponents/FollowUp/PreachAndTeach";
import InstantMessage from "../../pagesPatient/InstantMessage";
import MessageDetail from "../../coreUIComponents/SendMessage";
// import User from "../../pagesPatient/User";
import { RouteItem } from "../common";
import FollowUpUserList from "../../pagesPatient/FollowUpUser/FollowUpUserList";
import NotFound from "../../coreUIComponents/NotFound";
import { Navigate } from "react-router-dom";
import FormGatherWrapper from "../../coreUIComponents/FormEditor/FormGatherWrapper";
import FollowUpUserAdd from "../../pagesPatient/FollowUpUser/FollowUpUserAdd";
import FollowUpUserUpdate from "../../pagesPatient/FollowUpUser/FollowUpUserUpdate";
import FollowUpOutpatient from "../../pagesPatient/FollowUpOutPatient";
import FollowUpForm from "../../pagesPatient/FollowUpForm";
import FollowUpProjectAll from "../../pagesPatient/FollowUpProjectAll";

export const basePath = "/ui/patient-entry/";
const base = process.env.REACT_APP_NODE_ENV === "outer" ? "/nfmssxbd/" : "/";

export type TPatientRouterKey =
  | "home"
  | "medicalRecord"
  | "checkout"
  | "inspect"
  | "reportDetail"
  | "followUp"
  | "preachAndTeach"
  | "instantMessage"
  | "messageDetail"
  | "user"
  | "followUpUser"
  | "formGatner"
  | "followUpUserAdd"
  | "followUpUserUpdate"
  | "followUpOutpatient"
  | "followUpForm"
  | "followUpProjectAll"
  | "submitSuccessPage";

export const patientRouters = [
  {
    path: "",
    element: <Navigate to="home" />,
  },
  {
    id: "home",
    path: "home",
    element: <Home />,
    isShowFooterTabBar: true,
    title: "首页",
  },
  {
    id: "medicalRecord",
    path: "medicalRecord",
    element: <MedicalRecord />,
    title: "就诊记录",
  },
  {
    id: "checkout",
    path: "checkout",
    element: <ReportList />,
    title: "检验报告",
  },
  {
    id: "inspect",
    path: "inspect",
    element: <ReportList />,
    title: "检查报告",
  },
  {
    id: "reportDetail",
    path: "hospitalData/reportDetail",
    element: <ReportDetail />,
    title: "报告详情",
  },
  {
    id: "followUp",
    path: "followUp",
    element: <FollowUp />,
    // isShowFooterTabBar: true,
    title: "随访",
  },
  {
    id: "preachAndTeach",
    path: "preachAndTeach",
    element: <PreachAndTeach />,
  },
  {
    id: "instantMessage",
    path: "instantMessage",
    element: <InstantMessage />,
    isShowFooterTabBar: true,
    title: "消息",
  },
  {
    id: "messageDetail",
    path: "messageDetail",
    element: <MessageDetail />,
    title: "消息详情",
  },
  // {
  //   id: "user",
  //   path: "user",
  //   element: <User />,
  //   isShowFooterTabBar: true,
  //   title: " ",
  // },
  {
    id: "followUpUser",
    path: "followUpUser",
    element: <FollowUpUserList />,
    title: "随访人管理",
  },
  {
    id: "followUpUserAdd",
    path: "followUpUserAdd",
    element: <FollowUpUserAdd />,
    title: "添加随访人",
  },
  {
    id: "followUpUserUpdate",
    path: "followUpUserUpdate",
    element: <FollowUpUserUpdate />,
    title: "编辑",
  },
  {
    id: "followUpOutpatient",
    path: "followUpOutpatient",
    element: <FollowUpOutpatient />,
    title: "随访门诊安排",
  },
  {
    id: "followUpForm",
    path: "FollowUpForm",
    element: <FollowUpForm />,
    title: "待填写随访表单",
  },
  {
    id: "followUpProjectAll",
    path: "followUpProjectAll",
    element: <FollowUpProjectAll />,
    title: "查看全部随访",
  },
]
  .map((item) => {
    item.path = `${basePath}${item.path}`;
    return item;
  })
  .concat([
    {
      id: "formGatner",
      path: `${base}visit/ui/patient-entry/followUpMiniProgramFormGatner`,
      element: <FormGatherWrapper />,
    },
    {
      id: "404",
      path: "*",
      element: <NotFound />,
    },
  ]) as RouteItem<TPatientRouterKey>[];
console.log("[ patientRouters ]", patientRouters);
