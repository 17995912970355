/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Button, Card, Typography } from "../../components";
import usePatientStore from "../../store/patient";
import { useTheme } from "../../theme";
import { EditSOutline } from "antd-mobile-icons";
import { Radio } from "antd-mobile";
import { useRouter } from "../../router";
import { FollowUpUserInList } from "../../models/entity/followUpUser";
import { createSearchParams } from "react-router-dom";
import { serviceClient } from "../../service";

const FollowUpUserInfoItem = observer(function (
  props: PropsWithChildren<{
    title: ReactNode;
  }>
) {
  const theme = useTheme();
  const { title, children } = props;

  return (
    <Typography className="py-1" type="mini" truncation block>
      {title}
      <span
        css={css`
          color: ${theme.grayMainColor};
        `}
      >
        {children}
      </span>
    </Typography>
  );
});

export default observer(function FollowUpUserList() {
  const { followUpUserEntityStore } = usePatientStore();
  const theme = useTheme();
  const router = useRouter();
  const [selectedUser, setSelectedUser] = useState<
    FollowUpUserInList | undefined
  >(undefined);

  const navigatorToFollowUpUserAdd = useCallback(() => {
    router.navigateTo("followUpUserAdd");
  }, [router]);

  const navigatorToFollowUpUserUpdate = useCallback(
    (item: FollowUpUserInList) => {
      router.navigateTo({
        id: "followUpUserUpdate",
        search: `?${createSearchParams({
          id: item.roleId,
        })}`,
      });
    },
    [router]
  );

  const onSelectUser = useCallback(
    async (value: boolean, item: FollowUpUserInList) => {
      if (value) {
        setSelectedUser(item);
        await serviceClient.followUpUser.switchFollowUpUser(item.roleId);
        // await userLogicStore.initializeUserInfo();
        router.navigateTo("user");
      }
    },
    [router]
  );

  useEffect(() => {
    followUpUserEntityStore.getList();
  }, [followUpUserEntityStore]);

  useEffect(() => {
    setSelectedUser(followUpUserEntityStore.list?.[0]);
  }, [followUpUserEntityStore.list]);

  return (
    <div
      css={css`
        height: 100%;
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          flex-grow: 1;
          overflow-y: auto;
        `}
        className="page-padding"
      >
        {followUpUserEntityStore.list?.map((item) => {
          return (
            <div
              key={item.roleId}
              css={css`
                display: flex;
                justify-content: space-between;
              `}
              className="mb-5"
            >
              <Card
                css={css`
                  flex-grow: 1;
                `}
              >
                <Typography type="title" block className="pb-3">
                  {item.name}
                </Typography>
                <div>
                  <FollowUpUserInfoItem title="性别：">
                    {item.gender}
                  </FollowUpUserInfoItem>
                  <FollowUpUserInfoItem title="年龄：">
                    {item.age}
                  </FollowUpUserInfoItem>
                  <FollowUpUserInfoItem title="身份证号：">
                    {item.idCard}
                  </FollowUpUserInfoItem>
                  <FollowUpUserInfoItem title="手机号：">
                    {item.phone}
                  </FollowUpUserInfoItem>
                </div>
              </Card>
              <div
                className="w-11"
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  background-color: ${theme.boxBackgroundColor};
                `}
              >
                <Radio
                  checked={selectedUser?.roleId === item.roleId}
                  onChange={(value) => onSelectUser(value, item)}
                ></Radio>
                <EditSOutline
                  className="mt-8"
                  css={css`
                    font-size: 20px;
                  `}
                  onClick={() => navigatorToFollowUpUserUpdate(item)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="mx-4 my-2">
        <Button
          size="large"
          color="primary"
          fill="solid"
          block
          onClick={navigatorToFollowUpUserAdd}
        >
          添加随访人
        </Button>
      </div>
    </div>
  );
});
