import { useMemo } from "react";
import { URL_TOKEN_KEY, SAAS_TOKEN_KEY } from "../store/core/user";
import { useSearchParams } from "react-router-dom";

export const getAppUserIdentityType = () => {
  return "patient";
};

export const setSaasTokenByUrl = () => {
  const saasTokenByUrl = $utils.getURLParams(URL_TOKEN_KEY);
  if (saasTokenByUrl) {
    localStorage.setItem(SAAS_TOKEN_KEY, saasTokenByUrl);
    return saasTokenByUrl;
  }
};

export const useUrlQuery = () => {
  const [searchParams] = useSearchParams();

  const query = useMemo(
    () => Object.fromEntries(searchParams.entries()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams, window.location.search]
  );

  return query;
};

const getURLParams = (name: string, url = window.location.href) => {
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  // return decodeURIComponent(results[2].replace(/\+/g, " "));
  return decodeURIComponent(results[2]);
};

const $utils = {
  getURLParams,
};

export default $utils;
