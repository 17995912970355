import { useMemo } from "react";
import { NavigateOptions, useLocation, useNavigate } from "react-router-dom";
import { RouteItem } from "./common";
import { patientRouters, TPatientRouterKey } from "./Patient/config";

export const useRouter = function <TRouterKey extends TPatientRouterKey>() {
  const navigateTo = useNavigate();
  const location = useLocation();

  // const routers = patientRouters;

  const _useRouter = useMemo(
    () => ({
      navigateTo(to: any, options?: NavigateOptions) {
        if (typeof to === "string") {
          const id = to;
          const routerItem = patientRouters.find((item) => item.id === id);
          navigateTo(routerItem?.path ?? "/", options);
        } else {
          const _pathname =
            to.pathname === undefined && to.id
              ? patientRouters.find((item) => item.id === to.id)?.path
              : to.pathname;

          navigateTo(
            {
              ...to,
              pathname: _pathname,
            },
            options
          );
        }
      },
      currentRouter(): RouteItem<TRouterKey> | undefined {
        const _pathname = location.pathname;
        const routerItem = patientRouters.find((item) => {
          return item.path === _pathname;
        });
        return routerItem as RouteItem<TRouterKey>;
      },
    }),
    [location.pathname, navigateTo]
  );

  return _useRouter;
};
