/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo } from "react";
import Header from "../Home/Header";
// import FollowupIndex from "../../coreUIComponents/FollowUp";
import ProjectSelect from "../../coreUIComponents/FollowUp/ProjectSelect";
import { useAppUserIdentityType } from "../../hooks";
import useDoctorStore from "../../store/doctor";
import usePatientStore from "../../store/patient";
import { useTheme } from "../../theme";
import { useSearchParams } from "react-router-dom";
import FollowupPlan from "../../coreUIComponents/FollowUp/FollowupPlan";

export default observer(function FollowUp() {
  const theme = useTheme();
  const doctorStore = useDoctorStore();
  const patientStore = usePatientStore();
  const appUserIdentityType = useAppUserIdentityType();

  const [search] = useSearchParams();

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { followUpStore, appLogicStore } = useMemo(
    () => (isDoctor ? doctorStore : patientStore),
    [doctorStore, isDoctor, patientStore]
  );

  const info = useMemo(() => {
    return followUpStore.info;
  }, [followUpStore.info]);

  // const healthInfo = useMemo(() => {
  //   return followUpStore.patientHealthInfo;
  // }, [followUpStore.patientHealthInfo]);

  // const selectedProject = useMemo(() => {
  //   return followUpStore.selectedProject;
  // }, [followUpStore.selectedProject]);

  // const activeKey = useMemo(
  //   () => followUpStore.activeKey,
  //   [followUpStore.activeKey]
  // );

  const getPatientInfo = useCallback(() => {
    // selectedProject?.projectId &&
    //   followUpStore.patientInfo(
    //     selectedProject?.projectId,
    //     userProfile?.empiOrUserid || ""
    //   );
    // const idcard = localStorage.getItem("idcard");
    followUpStore.patientInfo(
      search.get("selectedProjectId") ?? "",
      search.get("empiOrUserid") ?? ""
      // idcard || ""
    );
  }, [followUpStore, search]);

  useEffect(() => {
    getPatientInfo();
    appLogicStore.setTitle("随访");
  }, [appLogicStore, getPatientInfo]);

  // useEffect(() => {
  //   selectedProject?.id && followUpStore.patientManagement(selectedProject?.id);
  // }, [followUpStore, selectedProject?.id]);

  return (
    <div
      className="h-full flex flex-col"
      css={css`
        background: ${theme.borderColor};
      `}
    >
      <div
        className="page-padding"
        css={css`
          background: ${theme.whiteColor};
        `}
      >
        <Header />

        <ProjectSelect
          keyId="pProject"
          type="OneBandColor"
          className="w-full justify-start rounded-md"
          selectedProjectId={search.get("selectedProjectId") ?? ""}
        />
      </div>

      {/* <FollowupIndex
        type="FollowUp"
        activeKey={activeKey}
        healthType="HealthManage"
        nodes={info?.nodes}
        healthInfo={healthInfo}
        patientId={info?.id}
        projectId={info?.projectId}
      /> */}
      <FollowupPlan
        type={"FollowUp"}
        nodes={info?.nodes}
        patientId={info?.id}
        projectId={info?.projectId}
      />
    </div>
  );
});
