import { hospitalHttpInstance } from "../utils/http";
import {
  GetHomeProjectListParamsType,
  GetHomeProjectListPromiseType,
} from "../models/entity/followUpProjectAll";

export class FollowUpProjectAllServiceClient {
  async getHomeProjectList(
    params: GetHomeProjectListParamsType
  ): Promise<GetHomeProjectListPromiseType[]> {
    // return hospitalHttpInstance.get("/mini/project/homeProject", { params });
    return hospitalHttpInstance.get("/mini/project/old/homeProject", {
      params,
    });
  }

  async generateIpid(params: any): Promise<any> {
    return hospitalHttpInstance.post("/mini/project/old/createPiid", params);
  }
}
