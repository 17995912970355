import {
  FollowUpUserInList,
  FollowUpUser,
} from "../models/entity/followUpUser";
import { saasHttpInstance } from "../utils/http";

export class FollowUpUserServiceClient {
  async getFollowUpUserList(): Promise<FollowUpUserInList[]> {
    return saasHttpInstance.get("/system/WxUser/getPatientRoles");
  }

  async getFollowUpUserDetail(roleId: string): Promise<FollowUpUser> {
    return saasHttpInstance.get(`/system/WxUser/getRoleInf/${roleId}`);
  }

  async addFollowUpUser(payload: Partial<FollowUpUser>) {
    return saasHttpInstance.post("/system/WxUser/addRoleInf", payload);
  }

  async updateFollowUpUser(payload: Partial<FollowUpUser>) {
    return saasHttpInstance.post("/system/WxUser/updateRoleInf", payload);
  }

  async deleteFollowUpUser(roleId: string) {
    return saasHttpInstance.get(`/system/WxUser/delRoleInf/${roleId}`);
  }
  async switchFollowUpUser(roleId: string) {
    return saasHttpInstance.get(`/system/WxUser/switchRole/${roleId}`);
  }
  async roleBinding(payload: Partial<FollowUpUser>) {
    return saasHttpInstance.post("/system/weChat/RoleBinding", payload);
  }
}
