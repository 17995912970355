/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import AppMain from "./AppMain";
import AppFooter from "./AppFooter";
import "./layout.css";
import { observer } from "mobx-react-lite";
import { usePatientRouter } from "../../router/Patient";
import usePatientStore from "../../store/patient";
import { Fragment, useEffect, useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import { SafeArea, Toast } from "antd-mobile";
import $utils from "../../utils";
import { useNavigate } from "react-router-dom";
import { serviceClient } from "../../service";
import { SAAS_TOKEN_KEY } from "../../store/core/user";

export default observer(function Layout() {
  const { appLogicStore } = usePatientStore();
  const router = usePatientRouter();
  const currentRouter = router.currentRouter();

  const navigateTo = useNavigate();
  const urlIdcard = $utils.getURLParams("idcard");

  // useLayoutEffect(() => {
  //   (async () => {
  //     const token = await serviceClient.user.login();
  //     const url = `ui/patient-entry/followUpProjectAll?idcard=${idcard}&token=${token}`;
  //     nav(url);
  //   })();
  // }, [idcard, nav]);

  useLayoutEffect(() => {
    if (urlIdcard) localStorage.setItem("idcard", urlIdcard);
  }, [urlIdcard]);

  useEffect(() => {
    (async () => {
      const _idcard = localStorage.getItem("idcard");
      const _token: any = localStorage.getItem(SAAS_TOKEN_KEY);

      if (!_idcard) {
        Toast.show("缺少身份证号");
        return;
      }

      let token = _token;

      if (_idcard && !_token) {
        token = await serviceClient.user.login();
      }

      // const url = `ui/patient-entry/followUpProjectAll?token=${token}`;
      if (window.location.pathname === "/") {
        router.navigateTo({
          id: "followUpProjectAll",
          search: `?token=${token}`,
        });
      }
    })();
  }, [navigateTo, router]);

  useEffect(() => {
    if (currentRouter?.title) {
      appLogicStore.setTitle(currentRouter.title);
    }
  }, [appLogicStore, currentRouter?.title]);

  // if (!userInfo) {
  //   return null;
  // }

  return (
    <Fragment>
      <Helmet>
        <title>{appLogicStore.title}</title>
      </Helmet>
      <div
        css={css`
          height: 100vh;
          display: flex;
          flex-direction: column;
        `}
      >
        <AppMain />
        {currentRouter?.isShowFooterTabBar && <AppFooter />}
        <SafeArea position="bottom" />
      </div>
    </Fragment>
  );
});
