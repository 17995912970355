/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { css } from "@emotion/react";
import { useTheme } from "../../../theme";
import { FormOriginType } from "../../FormEditor/FormGatherWrapper";
import { Typography, Tag } from "../../../components";
import {
  FollowupPlanInfoType,
  statusList,
  statusListType,
} from "../../../models/entity/followUpProject";

export interface CollapseTitleType {
  type: FormOriginType;
  time?: string[];
  currentItem: FollowupPlanInfoType;
}

export default React.memo(function CollapseTitle(props: CollapseTitleType) {
  const { type, time, currentItem } = props;
  const theme = useTheme();

  const iType = useMemo(() => {
    return type === "FollowUpProject";
  }, [type]);

  const currentStatus = useMemo(() => {
    return statusList.find(
      (item: statusListType) => item.value === String(currentItem?.status)
    );
  }, [currentItem.status]);

  return (
    <>
      {iType ? (
        <>
          <div className="flex justify-between items-center pr-2 mb-3">
            <Typography type="title-bold">{currentItem.nodeName}</Typography>
            {/* <div>
              <Typography colorType="primaryColor">
                {currentItem.commitCount}位
              </Typography>
              <Typography type="secondary">/</Typography>
              <Typography type="secondary">
                {currentItem.totalCount}位
              </Typography>
            </div> */}
          </div>
          <div>
            {currentItem?.followUp && (
              <Typography className="mr-3">
                <Tag color="success">门诊随访</Tag>
              </Typography>
            )}
            <Typography type="secondary">
              -{currentItem.floatingNum}天 +{currentItem.floatingDownNum}天
            </Typography>
          </div>
        </>
      ) : (
        <>
          <div
            className="w-full px-3 title-date"
            css={css`
              position: absolute;
              top: 1px;
              left: 0px;
              /* border-top: 2px solid
                ${theme[(currentStatus as statusListType)?.colorType]}; */
              /* background: ${currentStatus?.background}; */
            `}
          >
            <Typography type="secondary" colorType="grayLightColor">
              {/* format("YYYY-MM-DD") */}
              <>
                {time && time?.length > 0 ? (
                  <>
                    {time[0]}至{time[1]}
                  </>
                ) : (
                  "日期待获取..."
                )}
              </>
            </Typography>
            <div
              css={css`
                float: right;
                font-size: 14px;
                color: ${theme[(currentStatus as statusListType)?.colorType]};
                margin-top: 3px;
              `}
            >
              {currentStatus?.label}
            </div>
          </div>
          <div className={`${iType ? "" : "mt-7"} pr-2`}>
            <Typography type="title-bold">{currentItem.nodeName}</Typography>
          </div>
        </>
      )}
    </>
  );
});
