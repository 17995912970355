/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { Input, Toast } from "antd-mobile";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { AddCircleOutline } from "antd-mobile-icons";
import { useTheme } from "../../theme";
import { useAppUserIdentityType } from "../../hooks";
import useDoctorStore from "../../store/doctor";
import usePatientStore from "../../store/patient";
import { Typography } from "../../components";
import albumImg from "../../assets/image/album.svg";
import cameraImg from "../../assets/image/camera.svg";
import { serviceClient } from "../../service";
import { compressImg } from "../../utils/handleImg";
import { useLocation } from "react-router-dom";
import { MesHistoryType } from "../../models/entity/instantMessage";

export default observer(function ActionBar(props: {
  handleSend: (str: MesHistoryType) => void;
}) {
  const theme = useTheme();
  const { state } = useLocation();
  const doctorStore = useDoctorStore();
  const patientStore = usePatientStore();
  const appUserIdentityType = useAppUserIdentityType();
  const { handleSend } = props;

  const InputRef = useRef(null);

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { userLogicStore, userSendMesStore } = useMemo(
    () => (isDoctor ? doctorStore : patientStore),
    [doctorStore, isDoctor, patientStore]
  );

  const userProfile = useMemo(
    () => userLogicStore.userProfile,
    [userLogicStore.userProfile]
  );

  // 发送消息
  const sendMessage = useCallback(
    async (content: string, type: number) => {
      if (content) {
        const params = {
          empiOrUserid: userProfile?.empiOrUserid || "",
          mesContent: content,
          mesType: type,
          sessionId: userSendMesStore.sessionId as number,
          userType: isDoctor ? 2 : 1,
        };
        await userSendMesStore.fetchSendMessgae(params);
        handleSend({
          ...params,
          id: moment().valueOf(),
          mesStatus: 1, // 消息状态【0未读 | 1已读】
          mesTime: moment().format("YYYY-MM-DD"),
        });
      }
    },
    [handleSend, isDoctor, userProfile?.empiOrUserid, userSendMesStore]
  );

  const handleMessage = useCallback(() => {
    sendMessage(userSendMesStore.keyword, 0);
  }, [sendMessage, userSendMesStore.keyword]);

  const handleImage = useCallback(
    async (val: File) => {
      compressImg(val).then(async (res: any) => {
        const formData = new FormData();
        formData.append("multipartFile", res);
        const imgStr = await serviceClient.instantMessage.uploadFile(formData);
        sendMessage(imgStr, 1);
      });
    },
    [sendMessage]
  );

  const selectImage = useCallback(
    async (e: any) => {
      if (e.target.files?.length > 9) {
        Toast.show("一次最多选9张");
        return;
      }
      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        handleImage(e.target.files[i]);
      }
    },
    [handleImage]
  );

  // 从患者资料夹到发送消息
  useEffect(() => {
    if (state?.url) {
      for (let i = 0; i < state?.url?.length; i++) {
        const item = state?.url[i];
        const format = item?.substring(item?.lastIndexOf(".") + 1);
        const spliceLength = item?.lastIndexOf("/");
        const imageName = item.slice(spliceLength + 1);
        fetch(item)
          .then((res) => {
            return res.blob();
          })
          .then((blob) => {
            const imgFile = new File([blob], imageName, {
              type: `image/${format}`,
            });
            handleImage(imgFile);
          });
      }
    }
  }, [handleImage, state?.url]);

  const handleScroll = useCallback(
    (e: any) => {
      userSendMesStore.visible && userSendMesStore.setVisible(false);
      InputRef.current && (InputRef.current as any).blur();
    },
    [userSendMesStore]
  );

  useEffect(() => {
    window.addEventListener(
      "touchmove",
      (e) => {
        handleScroll(e);
      },
      true
    );
    return () => {
      window.removeEventListener(
        "touchmove",
        (e) => {
          handleScroll(e);
        },
        false
      );
    };
  }, [handleScroll]);

  return (
    <div
      className="px-3 pt-2 pb-8 w-full min-h-0 max-h-72"
      css={css`
        background: ${theme.whiteColor};
      `}
    >
      <div className="flex items-center">
        <Input
          ref={InputRef}
          enterKeyHint="send"
          value={userSendMesStore.keyword}
          placeholder="请输入"
          className="px-3 py-2 rounded-md"
          css={css`
            background: #f5f7fa;
          `}
          onFocus={() => {
            userSendMesStore.setVisible(false);
          }}
          onChange={(val: string) => {
            userSendMesStore.setKeyword(val);
          }}
          onEnterPress={handleMessage}
        />
        {/* <SmileOutline fontSize={32} className="mx-3" /> */}
        <AddCircleOutline
          fontSize={32}
          className="ml-3"
          onClick={userSendMesStore.setCollapse}
        />
      </div>
      {userSendMesStore.visible && (
        <div className="flex h-48">
          <div className="m-6 relative">
            <input
              type="file"
              accept="image/*"
              multiple
              className="inline-block w-full h-20 absolute inset-0 z-50 opacity-0"
              onChange={selectImage}
            />
            <div className="flex flex-col items-center">
              <img src={albumImg} alt="" className="mb-3" />
              <Typography type="secondary">照片</Typography>
            </div>
          </div>
          <div className="m-6 relative">
            <input
              type="file"
              accept="image/*"
              capture="environment"
              className="inline-block w-full h-20 absolute inset-0 z-50 opacity-0"
              onChange={selectImage}
            />
            <div className="flex flex-col items-center">
              <img src={cameraImg} alt="" className="mb-3" />
              <Typography type="secondary">拍摄</Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
