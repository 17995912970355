/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useTheme } from "../../../theme";
import { useLocation } from "react-router-dom";
import { useAppUserIdentityType } from "../../../hooks";
import useDoctorStore from "../../../store/doctor";
import usePatientStore from "../../../store/patient";
import { Typography } from "../../../components";
import { MesHistoryType } from "../../../models/entity/instantMessage";
import ImageViewers from "../../../coreUIComponents/ImageViewers";

interface ItemType {
  className?: string;
  data: MesHistoryType;
}

export default observer(function Item(props: ItemType) {
  const theme = useTheme();
  const { state } = useLocation();
  const doctorStore = useDoctorStore();
  const patientStore = usePatientStore();
  const appUserIdentityType = useAppUserIdentityType();

  const { className, data } = props;

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { userLogicStore, userSendMesStore } = useMemo(
    () => (isDoctor ? doctorStore : patientStore),
    [doctorStore, isDoctor, patientStore]
  );

  const { userProfile, userInfo } = useMemo(
    () => userLogicStore,
    [userLogicStore]
  );

  const patientInfo = useMemo(
    () => userSendMesStore.patientInfo,
    [userSendMesStore.patientInfo]
  );

  return (
    <div className={className}>
      <Typography type="mini">
        {data.empiOrUserid === userProfile?.empiOrUserid
          ? userInfo?.name
          : state.name}
      </Typography>
      {data.mesType === 1 ? (
        <div className="mt-2 py-2 rounded-md">
          <ImageViewers
            className="w-40 h-40"
            srcUrl={`/api/scf/profile/${data.mesContent}`}
            showFooter={isDoctor}
            patientId={patientInfo?.patientId}
            projectId={patientInfo?.projectId}
          />
        </div>
      ) : (
        <div
          className="mt-2 p-2 rounded-md"
          css={css`
            background: ${data.empiOrUserid === userProfile?.empiOrUserid
              ? theme.primaryColor
              : theme.whiteColor};
          `}
        >
          <Typography
            colorType={`${
              data.empiOrUserid === userProfile?.empiOrUserid
                ? "whiteColor"
                : "grayMainColor"
            }`}
          >
            {data?.mesContent}
          </Typography>
        </div>
      )}
    </div>
  );
});
