import {
  SerachPatientCountType,
  HealthInfoType,
  GetProjectListParamsType,
  GetProjectListPromiseType,
  SerachPatientCountPromiseType,
  FollowupPlanInfoType,
  EducationInfoType,
  talkType,
} from "../models/entity/followUpProject";
import { HomeParamsType } from "../models/entity/home";
import { hospitalHttpInstance, saasHttpInstance } from "../utils/http";

export class FollowUpServiceClient {
  async getProjectList(
    params: GetProjectListParamsType
  ): Promise<GetProjectListPromiseType[]> {
    return hospitalHttpInstance.get("/mini/project/list", { params });
  }

  async healthManagement(id: string): Promise<HealthInfoType> {
    return hospitalHttpInstance.get(`/mini/project/healthManagement/${id}`);
  }

  async patientManagement(id: string): Promise<HealthInfoType> {
    return hospitalHttpInstance.get(`/mini/project/patientManagement/${id}`);
  }

  async followUpPlanInfo(id: string): Promise<FollowupPlanInfoType[]> {
    return hospitalHttpInstance.get(`/mini/project/node/${id}`);
  }

  async education(id: string): Promise<EducationInfoType> {
    return hospitalHttpInstance.get(`/mini/project/education/${id}`);
  }

  async visitHome(
    params: HomeParamsType
  ): Promise<GetProjectListPromiseType[]> {
    return hospitalHttpInstance.get("/mini/project/old/visitHome", { params });
  }

  async serachPatientCount(
    params: SerachPatientCountType[]
  ): Promise<SerachPatientCountPromiseType[]> {
    return saasHttpInstance.post("/system/WxUser/serachPatientCount", params);
  }

  async writeForm(params: {
    formId: string;
    nodeId: string;
    patientId: string;
    projectId: string;
    type: number;
  }): Promise<FollowupPlanInfoType> {
    return hospitalHttpInstance.post("/mini/project/writeForm", params);
  }

  async talk(params: {
    empiOrUserid: string;
    projectId: string;
    patientIds: string[];
  }): Promise<talkType[]> {
    return hospitalHttpInstance.post("/mini/project/talk", params);
  }
}
