import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import HttpClient from "./base";
import { Toast } from "antd-mobile";
import { SAAS_TOKEN_KEY } from "../../store/core/user";

// 取消请求操作
const allPendingRequestsRecord: any[] = [];

const isReLogin = { show: false };
const removeAllPendingRequestsRecord = () => {
  allPendingRequestsRecord &&
    allPendingRequestsRecord.forEach((func) => {
      func(null);
    });
  // 移除所有记录
  allPendingRequestsRecord.splice(0);
};

const getConfirmation = () => {
  removeAllPendingRequestsRecord();
};

const instanceInterceptorsFunction = (httpInstance: AxiosInstance) => {
  httpInstance.interceptors.response.use(
    (res: AxiosResponse) => {
      return res;
    },
    (err) => {
      const code: number | string = err?.code;
      // if (window._settings.isWXLogin) {
      //   return Promise.reject(err);
      // }

      if (code === 401 || code === 403) {
        // 登录相关
        if (!isReLogin.show) {
          isReLogin.show = true;
          Toast.show({
            content: `${code === 401 ? "登录" : ""}状态已过期,请重新登录`,
            afterClose: async () => {
              isReLogin.show = false;
              const idcard = localStorage.getItem("idcard");
              localStorage.removeItem(SAAS_TOKEN_KEY);
              setTimeout(() => {
                window.location.href = `/?idcard=${idcard}`;
              }, 3000);
            },
          });
          return Promise.reject("无效的会话，或者会话已过期，请重新登录。");
        }
      }

      return Promise.reject(err);
    }
  );

  httpInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig<any>) => {
      const saasToken = localStorage.getItem(SAAS_TOKEN_KEY);
      config.headers["Authorization"] = saasToken;
      config.cancelToken = new axios.CancelToken((c) => {
        allPendingRequestsRecord.push(c);
      });

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

const saasHttpClient = new HttpClient();
const url = process.env.REACT_APP_NODE_ENV === "outer" ? "/nfmssxsf/" : "/";
const saasHttpInstance: AxiosInstance = saasHttpClient.create({
  // baseURL: `${url}api/scf`,
  baseURL: `${url}api`,
});
instanceInterceptorsFunction(saasHttpInstance);

const hospitalHttpClient = new HttpClient();
const hospitalHttpInstance: AxiosInstance = hospitalHttpClient.create({
  baseURL: `${url}api/${window._settings.hospitalCode}`,
});
instanceInterceptorsFunction(hospitalHttpInstance);

export { saasHttpInstance, hospitalHttpInstance, getConfirmation };
