/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import { css } from "@emotion/react";
import { useMemo } from "react";
import empty from "../../assets/image/empty.svg";
import tourist from "../../assets/image/tourist.svg";
import { Typography } from "../../components";
import { useAppUserIdentityType } from "../../hooks";
import useDoctorStore from "../../store/doctor";
import usePatientStore from "../../store/patient";
// import { useTheme } from "../../theme";

export default observer(function FormBrowse(props: { title: string }) {
  // const theme = useTheme();
  const doctorStore = useDoctorStore();
  const patientStore = usePatientStore();

  const appUserIdentityType = useAppUserIdentityType();

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { userLogicStore } = useMemo(
    () => (isDoctor ? doctorStore : patientStore),
    [doctorStore, isDoctor, patientStore]
  );

  // 判断是否是游客
  const isTourist = useMemo(() => {
    return !!userLogicStore.saasToken;
  }, [userLogicStore.saasToken]);

  return (
    <>
      <div
        className="block h-80 mt-11"
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <div className="text-center">
          {isTourist ? (
            <img className="w-40 h-40 bottom-0" src={empty} alt="" />
          ) : (
            <img className="w-40 bottom-0" src={tourist} alt="" />
          )}

          <Typography
            block
            colorType="grayLightColor"
            className={`${isTourist ? "mt-4" : "mt-0"}`}
          >
            {`${props.title}${isTourist ? "" : ""}`}
            {/* {!isTourist && (
              <span
                css={css`
                  cursor: pointer;
                  font-weight: 500;
                  color: ${theme.primaryColor};
                `}
                onClick={() => userLogicStore.logout()}
              >
                去登录
              </span>
            )} */}
          </Typography>
        </div>
      </div>
    </>
  );
});
