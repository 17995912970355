import axios, {
  AxiosResponse,
  AxiosError,
  CreateAxiosDefaults,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from "axios";
import { Toast } from "antd-mobile";
import { ToastHandler } from "antd-mobile/es/components/toast";

export type ErrorCodeMessageType = Record<number | "default", string>;
export interface TCustomConfigAttr {
  customConfig?: { isShowLoading?: boolean };
}

class HttpClient {
  config: CreateAxiosDefaults;
  errorCodeMessage: ErrorCodeMessageType;
  isNeedLoading: boolean;

  constructor(props?: {
    errorCodeMessage?: ErrorCodeMessageType;
    isNeedLoading?: boolean;
  }) {
    const defalutErrorCodeMessage = {
      401: "请重新登录",
      403: "当前操作没有权限",
      404: "访问资源不存在",
      default: "系统未知错误，请反馈给管理员",
    };
    const { errorCodeMessage, isNeedLoading = true } = props ?? {};

    this.config = {};
    this.errorCodeMessage = errorCodeMessage ?? defalutErrorCodeMessage;
    this.isNeedLoading = isNeedLoading;
  }

  static loadingCount: number = 0;
  static _toast: ToastHandler | undefined = undefined;

  create(payload: CreateAxiosDefaults) {
    this.config = payload;

    const httpInstance = axios.create({
      timeout: 50000,
      ...this.config,
    });

    httpInstance.defaults.headers["Content-Type"] =
      "application/json;charset=utf-8";
    this._setDefaultInterceptors(httpInstance);

    return httpInstance;
  }

  private _setDefaultInterceptors(httpInstance: AxiosInstance) {
    const errorCodeMessage = this.errorCodeMessage;

    httpInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig<any>) => {
        if (
          (config as TCustomConfigAttr & InternalAxiosRequestConfig)
            ?.customConfig?.isShowLoading !== false
        ) {
          this._showLoading();
        }

        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );

    httpInstance.interceptors.response.use(
      (res: AxiosResponse) => {
        if (
          (res.config as TCustomConfigAttr & InternalAxiosRequestConfig)
            ?.customConfig?.isShowLoading !== false
        ) {
          this._hideLoading();
        }

        const resData = res.data;
        const code: number = resData.code;

        if (code === 200) {
          return Promise.resolve(
            typeof resData === "object" && "data" in resData
              ? resData.data
              : resData
          );
        } else if (!code) {
          return Promise.resolve(resData);
        } else {
          const msg =
            resData.msg ??
            errorCodeMessage[code as keyof typeof errorCodeMessage] ??
            errorCodeMessage["default"];
          setTimeout(() => {
            Toast.show(msg);
          }, 500);

          return Promise.reject(resData);
        }
      },
      (error: AxiosError) => {
        if (
          (error.config as TCustomConfigAttr & InternalAxiosRequestConfig)
            ?.customConfig?.isShowLoading !== false
        ) {
          this._hideLoading();
        }

        // 取消请求拦截（不提示错误）
        if (error?.code === "ERR_CANCELED") {
          return new Promise(() => {});
        }

        let { message } = error;
        setTimeout(() => {
          Toast.show(message);
        }, 500);
        return Promise.reject(error);
      }
    );
  }

  private _showLoading() {
    if (this.isNeedLoading) {
      if (HttpClient.loadingCount === 0) {
        HttpClient._toast = Toast.show({
          icon: "loading",
          content: "加载中…",
          maskClickable: false,
          duration: 0,
        });
      }
      HttpClient.loadingCount++;
    }
  }

  private _hideLoading() {
    if (this.isNeedLoading) {
      HttpClient.loadingCount--;

      if (!HttpClient.loadingCount) {
        HttpClient._toast?.close();

        HttpClient.loadingCount = 0;
      }
    }
  }
}

export default HttpClient;
